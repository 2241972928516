import { subDays } from 'date-fns';
import type {
  ISupplier,
  ISupplierDetailVenue,
  ISupplier_Type,
} from '@bridebook/models/source/models/Suppliers.types';
import type { IVideo } from '@bridebook/models/source/models/Suppliers/Videos.types';
import type { ISupplier as IWeddingSupplier } from '@bridebook/models/source/models/Weddings/Suppliers.types';
import { getSearchUrlWithFilters } from '@bridebook/toolbox/src';
import { Market } from '@bridebook/toolbox/src/gazetteer';
import {
  IPackagesSections,
  PackagesSections,
} from '@bridebook/toolbox/src/map-packages-to-sections';
import type { IUISupplier, Slug } from '@bridebook/toolbox/src/types';
import { ISupplierData, type SupplierStorage } from 'app-shared/lib/supplier/supplier-types';
import { PricingCategory } from 'app-shared/lib/supplier/types';
import { getInsertA } from 'app-shared/lib/supplier/utils';
import { getI18n } from 'lib/i18n/getI18n';
import { format } from 'lib/i18n/utils/get-date-fns-format';
import { getPhotographyStyleOptions } from 'lib/search/get-photography-style-options';
import msg, { MsgKey } from 'lib/supplier/msg';
import { imgixBaseURL, truncate } from 'lib/utils';
import { getLocationContext } from '../weddings/selectors';

/**
 * get insertA in plural
 * @method getInsertAPlural
 * @param {string} slug - supplier slug to get insertA for
 *
 * TODO: [i18n] [bb-global] Inflection rules are only valid for English.
 */
export const getInsertAPlural = (slug: Slug): string => {
  const insertA: string = getInsertA(slug);
  let returnVal: string;
  const lastChar: number = insertA.length - 1;
  if (insertA[lastChar] === 'y') {
    const substr: string = insertA.substr(0, lastChar);
    returnVal = `${substr}ies`;
  } else {
    returnVal = `${insertA}s`;
  }
  return returnVal;
};

/**
 * Truncate text
 * Truncate text depending on device to specified lengths
 * @method textCut
 * @param {string} str - text to truncate
 * @param {number} desktop - output text length for desktop
 * @param {number} mobile - output text length for mobile
 * @param {boolean} isMobile - is mobile device
 */
export const textCut = (
  str: string,
  desktop?: number,
  mobile?: number,
  isMobile?: boolean,
): string => truncate(str, isMobile ? mobile : desktop);

export * from './utils/get-supplier-thumb';

export const getSupplierPhotos = (supplier?: IUISupplier) => {
  let photos: string[] = [];

  try {
    photos =
      typeof supplier?.photos === 'string'
        ? JSON.parse(supplier.photos).map((url: string) => `${imgixBaseURL}/${url}`)
        : [];
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Something wrong with supplier.photos...');
  }

  return photos;
};

export const getSupplierPhotographyStyles = (supplier: IUISupplier) =>
  getPhotographyStyleOptions().filter(({ key }) => !!supplier[key as keyof IUISupplier]);

export const getNextSuppliers = <T extends { publicId: string }>(
  list: T[],
  publicId: string,
  supplierAmount: number = 6,
): T[] => {
  if (list.length > 0) {
    const currentIndex: number = list.findIndex((item) => item.publicId === publicId) + 1;

    return list.slice(currentIndex, currentIndex + supplierAmount);
  }

  return [];
};

export const getUserEnquiryDate = (
  enquiryDates: Record<string, number>,
  supplierId: string,
): string | null => {
  const timestamp = enquiryDates?.[supplierId];

  return timestamp ? format(new Date(timestamp), 'dd/MM/yyyy') : null;
};

interface INextLink {
  as: string;
  href: string;
}

export interface FilterTypes extends Record<string, INextLink> {}

export type ILinkMap = Record<string, INextLink>;
type Filters = ISupplierDetailVenue['style'] & ISupplierDetailVenue['type'];

export const getUrls = ({
  area,
  areaContext,
  filters,
}: {
  area: string;
  areaContext?: ReturnType<typeof getLocationContext>;
  filters: Record<string, boolean>;
}): INextLink => {
  const href: string = getSearchUrlWithFilters({
    slug: 'venue',
    area,
    areaContext,
    filters,
    as: false,
  });

  const as: string = getSearchUrlWithFilters({
    slug: 'venue',
    area,
    areaContext,
    filters,
    as: true,
  });

  return { href, as };
};

export const convertToHashTag = (name: MsgKey): string => {
  const newName: string = msg[name]?.() || name;
  const lowerCaseName: string = typeof newName === 'string' ? newName.toLowerCase() : '';
  const removeWhiteSpace: string = lowerCaseName.replace(/ /g, '');
  return `#${removeWhiteSpace}`;
};

const getRelatedFilters = ({
  area,
  areaContext,
  filters,
}: {
  area: string;
  areaContext?: ReturnType<typeof getLocationContext>;
  filters: Filters;
}): ILinkMap =>
  (filters || []).reduce((acc: ILinkMap, item): ILinkMap => {
    const dict = {} as Record<typeof item, boolean>;

    dict[item] = true;
    acc[convertToHashTag(item)] = getUrls({ area, areaContext, filters: dict });

    return acc;
  }, {} as ILinkMap);

export const getRelatedTypesAndStyles = ({
  area,
  areaContext,
  style,
  type,
}: {
  area: string;
  areaContext?: ReturnType<typeof getLocationContext>;
  style: ISupplierDetailVenue['style'];
  type: ISupplierDetailVenue['type'];
}): ILinkMap => {
  const styles = style?.filter?.((t) => t !== 'other') || [];
  const types = type?.filter?.((t) => t !== 'otherVenueType') || [];
  //FIXME: casting because of some weird error here
  // @ts-ignore FIXME
  const filters = types.concat(styles);
  // @ts-ignore FIXME
  return getRelatedFilters({ area, areaContext, filters });
};

export const getPackagesPrice = (
  key: keyof typeof PackagesSections,
  index: number,
  supplierData?: IPackagesSections,
) => (supplierData?.[key]?.price as number[])?.[index];

export const getPricingCategoryTranslation = (pricingCategory: PricingCategory) => {
  const i18n = getI18n();
  const translations: Record<PricingCategory, string> = {
    [PricingCategory.Affordable]: i18n.t('filters:pricing.pricingCategories.affordable'),
    [PricingCategory.Moderate]: i18n.t('filters:pricing.pricingCategories.moderate'),
    [PricingCategory.Luxury]: i18n.t('filters:pricing.pricingCategories.luxury'),
    [PricingCategory.SuperLuxury]: i18n.t('filters:pricing.pricingCategories.superLuxury'),
  };
  return translations[pricingCategory];
};

interface PricingDetails {
  venueHireOnlyMinPrice: number;
  venueHireOnlyMaxPrice: number;
  packagesMinPricePp: number;
  packagesMaxPricePp: number;
  packagesTotalCostMinPrice: number;
  packagesTotalCostMaxPrice: number;
  supplierMaxPricePp: number;
  supplierMinPricePp: number;
}

export const getPricingDetails = (supplier: IUISupplier | IPackagesSections): PricingDetails => {
  const supplierData = supplier || {};
  const {
    // props returned from elastic
    venueHirePriceMin,
    venueHirePriceMax,
    pricePerHeadMin,
    totalInclusivePriceMax,
    totalInclusivePriceMin,
    supplierPriceMax,
    supplierPriceMin,
  } = supplierData as IUISupplier;

  let venueHireOnlyMinPrice = getPackagesPrice('venueHire', 0, supplierData as IPackagesSections);
  let venueHireOnlyMaxPrice = getPackagesPrice('venueHire', 1, supplierData as IPackagesSections);
  let packagesMinPricePp = getPackagesPrice('pricePerHead', 0, supplierData as IPackagesSections);
  let packagesMaxPricePp = getPackagesPrice('pricePerHead', 1, supplierData as IPackagesSections);
  let supplierMinPricePp = supplierPriceMin;
  let supplierMaxPricePp = supplierPriceMax;

  let packagesTotalCostMinPrice = getPackagesPrice(
    'totalInclusive',
    0,
    supplierData as IPackagesSections,
  );
  let packagesTotalCostMaxPrice = getPackagesPrice(
    'totalInclusive',
    1,
    supplierData as IPackagesSections,
  );

  venueHireOnlyMinPrice = parseInt(String(venueHireOnlyMinPrice || venueHirePriceMin || 0), 10);
  venueHireOnlyMaxPrice = parseInt(String(venueHireOnlyMaxPrice || venueHirePriceMax || 0), 10);
  packagesMinPricePp = parseInt(String(packagesMinPricePp || pricePerHeadMin || 0), 10);
  packagesMaxPricePp = parseInt(String(packagesMaxPricePp || totalInclusivePriceMax || 0), 10);
  supplierMaxPricePp = parseInt(String(supplierPriceMax || 0), 10);
  supplierMinPricePp = parseInt(String(supplierPriceMin || 0), 10);

  packagesTotalCostMinPrice = parseInt(
    String(packagesTotalCostMinPrice || totalInclusivePriceMin || 0),
    10,
  );
  packagesTotalCostMaxPrice = parseInt(
    String(packagesTotalCostMaxPrice || totalInclusivePriceMax || 0),
    10,
  );

  return {
    venueHireOnlyMinPrice,
    venueHireOnlyMaxPrice,
    packagesMinPricePp,
    packagesMaxPricePp,
    packagesTotalCostMinPrice,
    packagesTotalCostMaxPrice,
    supplierMaxPricePp,
    supplierMinPricePp,
  };
};

/**
 * Returns pricing string depending on available prices data
 * @method getPricingValueString
 * @param {object} supplier
 * @param {PricingCategory} PricingCategory
 * @param {boolean} isSearch
 * @param {string} currencySymbol
 * @returns {string}
 */
export const getPricingValueString = (
  currency: string,
  market: Market,
  supplier?: IUISupplier | IPackagesSections,
  pricingCategory?: PricingCategory,
  isSearch?: boolean,
): string => {
  const i18n = getI18n();

  const formatPrice = (value: number) =>
    market.formatCurrency(value, {
      currency,
      maximumFractionDigits: 0,
    });

  const {
    venueHireOnlyMinPrice,
    venueHireOnlyMaxPrice,
    packagesMinPricePp,
    packagesMaxPricePp,
    packagesTotalCostMinPrice,
    packagesTotalCostMaxPrice,
    supplierMaxPricePp,
    supplierMinPricePp,
  } = getPricingDetails(supplier as IUISupplier);

  if (supplierMinPricePp && supplierMaxPricePp === 0) {
    const price = formatPrice(supplierMinPricePp);
    return i18n.t('supplierCard:pricing.supplierFromPrice', { price });
  }

  if (supplierMinPricePp && supplierMaxPricePp) {
    const range = `${formatPrice(supplierMinPricePp)} - ${formatPrice(supplierMaxPricePp)}`;
    return isSearch ? range : i18n.t('supplierCard:pricing.venueRange', { range });
  }

  if (venueHireOnlyMinPrice && venueHireOnlyMaxPrice) {
    const range = `${formatPrice(venueHireOnlyMinPrice)} - ${formatPrice(venueHireOnlyMaxPrice)}`;

    return isSearch ? range : i18n.t('supplierCard:pricing.venueRange', { range });
  }

  if (venueHireOnlyMinPrice) {
    const price = formatPrice(venueHireOnlyMinPrice);

    return isSearch
      ? i18n.t('supplierCard:pricing.venueMin.search', { price })
      : i18n.t('supplierCard:pricing.venueMin.noSearch', { price });
  }

  if (venueHireOnlyMaxPrice) {
    const price = formatPrice(venueHireOnlyMaxPrice);

    return isSearch
      ? i18n.t('supplierCard:pricing.venueMax.search', 'Up to {{price}}', { price })
      : i18n.t('supplierCard:pricing.venueMax.noSearch', { price });
  }

  if (packagesTotalCostMinPrice && packagesTotalCostMaxPrice) {
    const range = `${formatPrice(packagesTotalCostMinPrice)} - ${formatPrice(
      packagesTotalCostMaxPrice,
    )}`;

    return isSearch ? range : i18n.t('supplierCard:pricing.packageRange.noSearch', { range });
  }

  if (packagesTotalCostMinPrice) {
    const price = formatPrice(packagesTotalCostMinPrice);

    return isSearch
      ? i18n.t('supplierCard:pricing.packageMin.search', { price })
      : i18n.t('supplierCard:pricing.packageMin.noSearch', { price });
  }

  if (packagesTotalCostMaxPrice) {
    const price = formatPrice(packagesTotalCostMaxPrice);

    return isSearch
      ? i18n.t('supplierCard:pricing.packageMax.search', { price })
      : i18n.t('supplierCard:pricing.packageMax.noSearch', { price });
  }

  if (packagesMinPricePp && packagesMaxPricePp) {
    const range = `${formatPrice(packagesMinPricePp)} - ${formatPrice(packagesMaxPricePp)}`;

    return i18n.t('supplierCard:pricing.ppRange', { range });
  }

  if (packagesMinPricePp) {
    const price = formatPrice(packagesMinPricePp);

    return i18n.t('supplierCard:pricing.ppMin', { price });
  }

  if (packagesMaxPricePp) {
    const price = formatPrice(packagesMaxPricePp);

    return i18n.t('supplierCard:pricing.ppMax', { price });
  }

  if (pricingCategory) {
    return getPricingCategoryTranslation(pricingCategory) || i18n.t('supplierCard:pricing.poa');
  }

  return i18n.t('supplierCard:pricing.poa');
};

export interface ReviewFromType {
  name: string;
  value: string;
  label: string;
}

/**
 * Returns "From" select options for review form
 * @method getReviewFromOptions
 * @returns {Array}
 */
export const getReviewFromOptions = (): ReviewFromType[] => {
  const i18n = getI18n();
  return [
    {
      name: 'from',
      value: 'happyCouple',
      label: i18n.t('supplier:form.from.input.dropdown.option.couple'),
    },
    {
      name: 'from',
      value: 'couplesParents',
      label: i18n.t('supplier:form.from.input.dropdown.option.parents'),
    },
    {
      name: 'from',
      value: 'happyGuests',
      label: i18n.t('supplier:form.from.input.dropdown.option.guests'),
    },
    {
      name: 'from',
      value: 'suppliers',
      label: i18n.t('supplier:form.from.input.dropdown.option.suppliers'),
    },
    {
      name: 'from',
      value: 'other',
      label: i18n.t('supplier:form.from.input.dropdown.option.other'),
    },
  ];
};

/**
 * Returns yesterday date object
 * @returns Date
 */
export const getYesterday = () => subDays(new Date(), 1);

export const isSupplierVenueType = (type?: ISupplier_Type) => type === 'venue';

export const isSupplierVenue = (supplier?: ISupplier | IUISupplier | IWeddingSupplier | null) =>
  isSupplierVenueType(Array.isArray(supplier?.type) ? supplier?.type[0] : supplier?.type);

export const isISupplierType = (supplier: any): supplier is ISupplier => !!supplier.address;

export const isSupplierWithPricingDetails = (
  supplier: IPackagesSections | IUISupplier | undefined,
) => Object.values(getPricingDetails(supplier as IUISupplier)).some((value) => value > 0);

export const isTopSpotVenue = (supplier?: IUISupplier) =>
  supplier?.supplierBoosted && isSupplierVenueType(supplier?.type);

export const getMoodBoardMediaTypeByVideo = (video: IVideo) =>
  video.type === 'videoTour' ? 'virtual tour' : 'video';

export const isSupplierRegistered = (supplier: ISupplier) => Number(supplier?.users?.length) > 0;

export const getSupplierTier = (supplierData?: ISupplierData) => supplierData?.premium?.tier || 0;

export const mapSupplierStorageToUISupplier = (s: SupplierStorage[]): IUISupplier[] =>
  s.map(({ photos, ...rest }) => ({
    ...rest,
    photos: JSON.stringify(photos.map((photo) => `${photo.public_id}.${photo.ext}`)),
    thumbnail: photos[0] ? `${photos[0].public_id}.${photos[0].ext}` : undefined,
  }));

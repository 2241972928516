import { getI18n } from 'lib/i18n/getI18n';

// These filters have been moved from 'lib/supplier/msg' since these can be
// displayed in every page with the search bar, so they have to be always
// loaded.
const filtersTexts = {
  affordable: () => getI18n().t('filters:affordable'),
  alcoholLicense: () => getI18n().t('filters:alcoholLicense'),
  alternative: () => getI18n().t('filters:alternative'),
  asian: () => getI18n().t('filters:asian'),
  asianCatering: () => getI18n().t('filters:asianCatering'),
  asianWeddingSpecialist: () => getI18n().t('filters:asianWeddingSpecialist'),
  bar: () => getI18n().t('filters:bar'),
  barn: () => getI18n().t('filters:barn'),
  beach: () => getI18n().t('filters:beach'),
  bestOfBritain: () => getI18n().t('filters:bestOfBritain'),
  blankCanvas: () => getI18n().t('filters:blankCanvas'),
  castle: () => getI18n().t('filters:castle'),
  casual: () => getI18n().t('filters:casual'),
  churchWithinWalkingDistance: () => getI18n().t('filters:churchWithinWalkingDistance'),
  cityHotelCityVenue: () => getI18n().t('filters:cityHotelCityVenue'),
  classic: () => getI18n().t('filters:classic'),
  conferenceCentre: () => getI18n().t('filters:conferenceCentre'),
  countryHouseManorHouse: () => getI18n().t('filters:countryHouseManorHouse'),
  cruiseBoatYacht: () => getI18n().t('filters:cruiseBoatYacht'),
  disabledAccess: () => getI18n().t('filters:disabledAccess'),
  exclusiveUse: () => getI18n().t('filters:exclusiveUse'),
  externalCateringPermitted: () => getI18n().t('filters:externalCateringPermitted'),
  formal: () => getI18n().t('filters:formal'),
  gardenOutdoor: () => getI18n().t('filters:gardenOutdoor'),
  golfCourse: () => getI18n().t('filters:golfCourse'),
  greatViews: () => getI18n().t('filters:greatViews'),
  halalCatering: () => getI18n().t('filters:halalCatering'),
  hasAccommodation: () => getI18n().t('filters:hasAccommodation'),
  hasBridebookOffer: () => getI18n().t('filters:hasBridebookOffer'),
  hasDiscounts: () => getI18n().t('filters:hasDiscounts'),
  hasGift: () => getI18n().t('filters:hasGift'),
  hasLateAvailability: () => getI18n().t('filters:hasLateAvailability'),
  hasQualityPricing: () => getI18n().t('filters:hasQualityPricing'),
  hasWeddingFair: () => getI18n().t('filters:hasWeddingFair'),
  historic: () => getI18n().t('filters:historic'),
  hotel: () => getI18n().t('filters:hotel'),
  inHouseCatering: () => getI18n().t('filters:inHouseCatering'),
  intimate: () => getI18n().t('filters:intimate'),
  jewishWeddingSpecialist: () => getI18n().t('filters:jewishWeddingSpecialist'),
  kosherCatering: () => getI18n().t('filters:kosherCatering'),
  lateNightExtensionAvailable: () => getI18n().t('filters:lateNightExtensionAvailable'),
  lovelyGrounds: () => getI18n().t('filters:lovelyGrounds'),
  luxury: () => getI18n().t('filters:luxury'),
  marqueePermitted: () => getI18n().t('filters:marqueePermitted'),
  modern: () => getI18n().t('filters:modern'),
  museumAttraction: () => getI18n().t('filters:museumAttraction'),
  muslimWeddingSpecialist: () => getI18n().t('filters:muslimWeddingSpecialist'),
  onsiteParking: () => getI18n().t('filters:onsiteParking'),
  otherVenueType: () => getI18n().t('filters:otherVenueType'),
  petFriendly: () => getI18n().t('filters:petFriendly'),
  otherWeddingSpecialist: () => getI18n().t('filters:otherWeddingSpecialist'),
  outdoor: () => getI18n().t('filters:outdoor'),
  placeOfWorship: () => getI18n().t('filters:placeOfWorship'),
  pubRestaurant: () => getI18n().t('filters:pubRestaurant'),
  romantic: () => getI18n().t('filters:romantic'),
  resort: () => getI18n().t('filters:resort'),
  rooftop: () => getI18n().t('filters:rooftop'),
  rustic: () => getI18n().t('filters:rustic'),
  sportingVenueStadium: () => getI18n().t('filters:sportingVenueStadium'),
  statelyHome: () => getI18n().t('filters:statelyHome'),
  stylish: () => getI18n().t('filters:stylish'),
  townHallRegistryOffice: () => getI18n().t('filters:townHallRegistryOffice'),
  transportation: () => getI18n().t('filters:transportation'),
  unique: () => getI18n().t('filters:unique'),
  uniqueVenueType: () => getI18n().t('filters:uniqueVenueType'),
  unusual: () => getI18n().t('filters:unusual'),
  urban: () => getI18n().t('filters:urban'),
  warehouseFactory: () => getI18n().t('filters:warehouseFactory'),
  weddingLicence: () => getI18n().t('filters:weddingLicence'),
  wifi: () => getI18n().t('filters:wifi'),
  winery: () => getI18n().t('filters:winery'),
  isCurated: () => getI18n().t('filters:isCurated'),
};

export type FiltersType = typeof filtersTexts;
export type FiltersKey = keyof FiltersType;

export default filtersTexts;
